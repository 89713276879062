import './App.css';
import { LoadingScreen } from './LoadingScreen';

function App() {
  return (
    <div className="App">
      <LoadingScreen/>
    </div>
  );
}

export default App;
