import React from 'react';
import shipaidLogo from './assets/images/shipaid_logo_300x300.webp';

export const LoadingScreen = () => {
  return (
    <div className="flex items-center justify-center h-screen" style={{ marginTop: 400 }}>
      <div
        style={{
          borderColor: 'rgba(37,99,235, 0.2)',
          borderTopColor: '#2564eb',
        }}
        className="inline-block border-4 rounded-full animate-spin w-20 h-20"
        role="spinbutton"
      />
      <img
        alt="shipaid logo"
        src={shipaidLogo}
        style={{
          width: 50,
          height: 'auto'
        }}
        className="w-24 h-24 absolute"
      />
    </div>
  );
};
